import { reactive, watch, computed, toRefs, ref } from "vue";
import { getData, getDataAsync, getDataWithoutLimit } from "helpers/_api";
import { config, env, apiHostname } from "config";
import { checkLogin } from "services/JwtService.js";

import { cache, initCache, clearCache } from "helpers/_cache";

import { useRoute } from "vue-router";
import { getUsergroupFilters } from "store/filters";

import router from "config/router";

const cacheableValues = {
	company: null,
	contact: null,
	user_id: null
};

const portalDefaults = {
	loading: false,
	unauthorized: false,
	fetching: [],
	me: {
		data: {
			id: null,
			extra: {
				usergroup_ids: []
			}
		}
	},
	...cacheableValues,

	user: [],
	workflowboard: [],
	workflowphase: [],
	workflowcard: [],
	workflowcardtype: []
};
export const portalStore = reactive({
	...portalDefaults
});
Object.keys(cacheableValues).forEach((key) => {
	initCache("portal", key, portalStore);
});

// Our data
export const me = computed(() => portalStore.me);
export const user_id = computed(() => portalStore.me.data.id);
export const usergroup_ids = computed(() => portalStore.me.data.extra.usergroup_ids);
export const unauthorized = computed(() => portalStore.unauthorized);

export const company = computed(() => portalStore.company ?? cache('portal', 'company'));
export const contact = computed(() => portalStore.contact ?? cache('portal', 'contact'));
export const role = computed(() => {
	const tmpValue = value(contact.value, env("variables.contact.properties.role"));
	if (tmpValue) {
		return tmpValue.split(",");
	}
	return [];
});

const user_fallback = "https://i0.wp.com/agency.ibizz.nl/public/themes/admin/_compiled/images/avatar.jpg?ssl=1";
export const user_avatar = (id = user_id.value) => {
	return computed(() => {
		if (`${id}` === `${user_id.value}`) {
			return portalStore.me?.data?.avatar_url ?? user_fallback;
		}
		return portalStore.user.find(a => `${a.data.id}` === `${id}`)?.data?.avatar_url ?? user_fallback;
	}).value;
};

const map = {
	"contact_id" : computed(() => contact.value?.id ?? null)
};
export const mapValue = (key) => {
	return map[key] ?? undefined;
};

// export const values = (keys) => {
// 	return computed(() => {
// 		const result = {};
// 		keys.forEach(key => {
// 			result[key] = portalStore.json.data?.[key] ?? "";
// 		});
// 		return result;
// 	}).value;
// };

export const resolveValueParts = (key, parent_key) => {
	if (key === "id") {
		return portalStore[parent_key]?.data?.[key];
	} else if (parent_key) {
		return value(portalStore[parent_key], key) ?? null;
	} else if (key === "user_id") {
		return user_id.value ?? null;
	}
	return undefined;

};

const resolveField = (data, target_value, target_key = "name") => {
	return computed(() => {
		const field = data?.extra?.workflowproperties.find(a => a.field[target_key] === target_value);
		if (field && field.value && field.field.type === 'CheckboxField'){
			const tmpValue = typeof field.value === "object" ? Object.values(field.value) : field.value;
			if (Array.isArray(tmpValue)) {
				field.value = tmpValue.join(', ');
			}
		}
		return field ? field : undefined;
	}).value;
};
const resolveValue = (data, target_value, target_key = "name") => {
	return computed(() => {
		const field = resolveField(data, target_value, target_key);
		return field ? field.value : undefined;
	}).value;
};

export const field = (data, target_value, target_key = "name") => {
	return computed(() => {
		return resolveField(data, target_value , target_key);
	}).value;
};
export const value = (data, target_values, target_key = "name") => {
	return computed(() => {
		if (!target_values) return "";
		if (typeof target_values === "string") {
			target_values = [target_values];
		}

		const result = [];
		target_values.forEach(key => {
			result.push(resolveValue(data, key, target_key) ?? "");
		});
		return result.filter(a => a !== "").join(" ");
	}).value;
};

export const fetchRelated = (key = "contact") => {
	if (!user_id.value) {
		return;
	}

	const filters = env(`variables.${key}.filters`);
	let failed_resolving = false;
	filters.forEach(filter => {
		const target = filter[1];
		// Make sure we target the correct key
		let tmpValue = JSON.parse(JSON.stringify(target.linked_ids ?? ""));
		if (!tmpValue) {
			tmpValue = JSON.parse(JSON.stringify(target.v ?? target.value));
		}
		// Make sure it is a array to work with
		if (!Array.isArray(tmpValue)) {
			tmpValue = [tmpValue];
		}
		// Loop through our values
		tmpValue.map(a => `${a}`).forEach((value, index) => {
			// console.log("value", value);
			// console.log("index", index);
			if (value.startsWith("%") && value.endsWith("%")) {
				// Replace % with our value
				const tmpValue = value.replace(/%/g, "").replace(/%/g, "");
				const tmpParts = tmpValue.split(".");
				const tmpKey = tmpParts[tmpParts.length - 1];
				const tmpParent = tmpParts.length > 1 ? tmpParts[0] : null;
				// console.log("tmpValue",tmpValue);
				// console.log("tmpParts",tmpParts);
				// console.log("tmpKey",tmpKey);
				// console.log("tmpParent",tmpParent);
				if (tmpParent && !portalStore.fetching.includes(tmpParent)) {
					portalStore.fetching.push(tmpParent);
					// Listen for changes
					fetchRelated(tmpParent);
					watch(
						() => portalStore[tmpParent],
						(parent_object) => {
							if (!parent_object) return;
							// Restart our current function based on new input
							fetchRelated(key);
						}, {
							immediate: true
						}
					);
				}

				const newValue = resolveValueParts(tmpKey, tmpParent);
				if (!newValue) {
					failed_resolving = true;
					return;
				}
				if (target.linked_ids) {
					target.linked_ids[index] = newValue;
				} else {
					if (!(value in target)) {
						target.value = [];
					}
					target.value[index] = newValue;
					delete target.v;
				}
			}
		});
	});
	if (!failed_resolving) {
		portalStore.fetching.push(key);
		// console.log("filters", filters);
		getDataAsync(`${apiHostname}/api/v1.0/workflowcard`, [
				...filters,
			[
				"FieldFilter",
				{
					"column": "workflowcardtype_id",
					"value": env(`variables.${key}.workflowcardtype_id`)
				}
			]
		]).then((json) => {
			portalStore[key] = json.data?.length ? json.data[0] : undefined;
		});
	} else {
		// console.log("key", key);
		portalStore[key] = undefined;
	}
	return;
};

const fetchMe = (renew = false) => {
	if (portalStore.loading || (me.value.data.id !== null && !renew)) return;
	if (!checkLogin()) return;
	portalStore.loading = true;
	// Set empty object so it can't be queried multiple times
	getData(`${apiHostname}/api/v1.0/me`).then((json) => {
		if (cache("portal", "user_id") && (cache("portal", "user_id") !== json.data.id)) {
			clearCache();
			portalStore.contact = null;
			portalStore.company = null;
			portalStore.user_id = null;
		}
		portalStore.me = json;
		portalStore.user_id = json.data.id;
		portalStore.loading = false;
		// console.log("me", portalStore.me);
	}).catch(error => {
		clearCache();
		resetMe();
		portalStore.contact = undefined;
		portalStore.company = undefined;
		portalStore.user_id = undefined;
		portalStore.unauthorized = true;

		router.push("/");
	});
};
const resetMe = () => {
	portalStore.me = {...portalDefaults.me};
	portalStore.user_id = null;
	portalStore.loading = false;
};



export const related_value = (value) => {
	return computed(() => {
		// Replace % with our value
		const tmpValue = value.replace(/%/g, "").replace(/%/g, "");
		const tmpParts = tmpValue.split(".");
		const tmpKey = tmpParts[tmpParts.length - 1];
		const tmpParent = tmpParts.length > 1 ? tmpParts[0] : null;
		// console.log("tmpValue",tmpValue);
		// console.log("tmpParts",tmpParts);
		// console.log("tmpKey",tmpKey);
		// console.log("tmpParent",tmpParent);
		if (tmpParent && !portalStore.fetching.includes(tmpParent)) {
			portalStore.fetching.push(tmpParent);
			// Listen for changes
			fetchRelated(tmpParent);
		}
		return resolveValueParts(tmpKey, tmpParent);
	}).value;
};

export const item = (module_access, id) => {
	return computed(() => {
		if (!(module_access in portalStore)) {
			portalStore[module_access] = [];
		}
		return portalStore[module_access].find(a => (`${a.data.id}` === `${id}`));
	}).value;
};
export const fetchItem = (module_access, id) => {
	return new Promise(resolve => {
		if (typeof id === "undefined" || id === "undefined") return resolve(undefined);
		if (!(module_access in portalStore)) {
			portalStore[module_access] = [];
		}
		const defined = portalStore[module_access].find(a => `${a.data.id}` === `${id}`);
		if (defined) {
			return resolve(defined);
		};
		portalStore[module_access].push({data: {id: id}});
		getDataAsync(`${apiHostname}/api/v1.0/${module_access}/${id}`).then((json) => {
			const index = portalStore[module_access].findIndex(a => `${a.data.id}` === `${id}`);
			portalStore[module_access][index] = json;
			resolve(portalStore[module_access].find(a => `${a.data.id}` === `${id}`));
		});
	});
};


// Fetch functions
const getItems = (module_access, id) => {
	return new Promise((resolve, reject) => {
		let apiUrl = config.value[module_access].url;
		// If ID exists, pass it to the url
		id && (apiUrl = `${apiUrl}/${id}`);
		const stateFilters = [
			[
				"FieldFilter",
				{
					"column": "state",
					"value": "Online"
				}
			]
		];
		const tmpUsers = {
			data: []
		};
		getDataWithoutLimit(`${apiHostname}${apiUrl}`, stateFilters, tmpUsers, "data").then(() => {
			const tmpData = tmpUsers.data.map(a => {
				return {data: a};
			});
			resolve(tmpData);
		});
	});
};

let userWatcher;
export const init = () => {
	// Keep track of the route to handle changes
	const route = useRoute();

	// Check for login state, if logged in make sure me is fetched
	watch(
	    () => route.path,
	    (path) => {
	        if (!checkLogin() || path === "/login") {
				return resetMe();
			}
			fetchMe();
	    },
	    {
	        immediate: true
	    }
	);
	// Based on our user_id and configuration set within public/env/...json
	// Get our company and contact information
	const pastUserId = ref(null);
	watch(
		() => [user_id.value, env(`variables.company.filters`), env(`variables.contact.filters`)],
		(values) => {
			if (values.some(a => typeof a === "undefined")) return;
			// Skip when already loading
			if (pastUserId.value === user_id.value) return;
			// Get our company
			fetchRelated("company");
			// Store our user Id for reference.
			pastUserId.value = user_id.value;
		}
	);

	// Store our user information
	// @TODO Check if the get for users is needed, since unclear
	// @TODO Check if the get for users is needed, since unclear
	// @TODO Check if the get for users is needed, since unclear
	// @TODO Check if the get for users is needed, since unclear
	// watch(
	// 	() => user_id.value,
	// 	() => {
	// 		if (!user_id.value) return;
	//
	// 		userWatcher = watch(
	// 			() => route.path,
	// 			(path) => {
	// 				if (!checkLogin() || path === "/login") return;
	// 				!portalStore["user"].length && getItemsAndStore("user");
	// 				userWatcher && userWatcher();
	// 			},
	// 			{
	// 				immediate: true
	// 			}
	// 		);
	// 	}
	// );
};


// Added watcher to clear cache when user_id changes
watch(
	() => user_id.value,
	(newId, oldId) => {
		if (!oldId || !newId) return;
		// Clear our cache since user_id changed
		clearCache();
	}
);

watch(
	() => usergroup_ids.value,
	(ids) => {
		if (!ids) return;

		getUsergroupFilters();
	},
	{
		immediate: true
	}
);