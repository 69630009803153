import { computed } from "vue";
import {createRouter, createWebHistory} from "vue-router";

import { env } from "config";

import { checkLogin } from "services/JwtService";

import layout from "layout/layout.vue";

// Defaults
import error404 from "view/error/404";
import error500 from "view/error/500";

// Dashboard related templates
import dashboardLogin from "view/dashboard/login";
import dashboardHome from "view/dashboard/home";
import dashboardView from "view/dashboard/view";

// Specific pages
import dashboardInvoice from "view/dashboard/invoice";
import dashboardSettings from "view/dashboard/settings";
import dashboardSupport from "view/dashboard/support";

// Validate visiblity
import { checkVisibility } from "store/view";

const routes = [
	{
		path: "/",
		component: layout,
		children: [
			{
				path: "",
				name: "Dashboard home",
				component: dashboardHome,
				meta: {
					module: "Dashboard",
					action: "view"
				}
			},
			{
				path: "/profile",
				name: "Dashboard profile",
				component: dashboardView,
				meta: {
					module: "Dashboard",
					action: "profile"
				}
			},

			{
				path: "/calendar",
				name: "Dashboard calendar",
				component: dashboardView,
				meta: {
					module: "Dashboard",
					action: "calendar"
				}
			},
			{
				path: "/invoice",
				name: "Dashboard invoice",
				component: dashboardInvoice,
				meta: {
					module: "Dashboard",
					action: "invoice"
				}
			},
			{
				path: "/ticket",
				name: "Dashboard ticket",
				component: dashboardView,
				meta: {
					module: "Dashboard",
					action: "ticket"
				}
			},
			{
				path: "/service",
				name: "Dashboard service",
				component: dashboardView,
				meta: {
					module: "Dashboard",
					action: "service"
				}
			},
			{
				path: "/content-check",
				alias: [
					"/content-check/:payload"
				],
				name: "Dashboard content-check",
				component: dashboardView,
				meta: {
					module: "Dashboard",
					action: "content-check"
				}
			},
			{
				path: "/content-planned",
				alias: [
					"/content-planned/:payload"
				],
				name: "Dashboard content-planned",
				component: dashboardView,
				meta: {
					module: "Dashboard",
					action: "content-planned"
				}
			},
			{
				path: "/content-history",
				alias: [
					"/content-history/:payload"
				],
				name: "Dashboard content-history",
				component: dashboardView,
				meta: {
					module: "Dashboard",
					action: "content-history"
				}
			},

			// Macromedics
			{
				path: "/project",
				alias: [
					"/project/:payload"
				],
				name: "Dashboard project",
				component: dashboardView,
				meta: {
					module: "Dashboard",
					action: "project"
				}
			},
			{
				path: "/order",
				alias: [
					"/order/:payload"
				],
				name: "Dashboard order",
				component: dashboardView,
				meta: {
					module: "Dashboard",
					action: "order"
				}
			},
			{
				path: "/contact",
				alias: [
					"/contact/:payload"
				],
				name: "Dashboard contact",
				component: dashboardView,
				meta: {
					module: "Dashboard",
					action: "contact"
				}
			},
			{
				path: "/organisation",
				alias: [
					"/organisation/:payload"
				],
				name: "Dashboard organisation",
				component: dashboardView,
				meta: {
					module: "Dashboard",
					action: "organisation"
				}
			},
			{
				path: "/my-organisation",
				alias: [
					"/my-organisation/:payload"
				],
				name: "Dashboard my-organisation",
				component: dashboardView,
				meta: {
					module: "Dashboard",
					action: "my-organisation"
				}
			},
			// Macromedics
			{
				path: "/offertes",
				alias: [
					"/offertes/:payload"
				],
				name: "Dashboard offertes",
				component: dashboardView,
				meta: {
					module: "Dashboard",
					action: "offertes"
				}
			},
			{
				path: "/complaint",
				alias: [
					"/complaint/:payload"
				],
				name: "Dashboard complaint",
				component: dashboardView,
				meta: {
					module: "Dashboard",
					action: "complaint"
				}
			},
			{
				path: "/sparepart",
				alias: [
					"/sparepart/:payload"
				],
				name: "Dashboard sparepart",
				component: dashboardView,
				meta: {
					module: "Dashboard",
					action: "sparepart"
				}
			},

			// Jobsegroep
			{
				path: "/aanmelding",
				alias: [
					"/aanmelding/:payload"
				],
				name: "Dashboard aanmelding",
				component: dashboardView,
				meta: {
					module: "Dashboard",
					action: "aanmelding"
				}
			},

			// {
			// 	path: "/settings",
			// 	name: "Dashboard settings",
			// 	component: dashboardSettings,
			// 	meta: {
			// 		module: "Dashboard",
			// 		action: "settings"
			// 	}
			// },
			{
				path: "/support",
				name: "Dashboard support",
				component: dashboardSupport,
				meta: {
					module: "Dashboard",
					action: "support"
				}
			},
			// Default pages
			// {
			// 	path: "/productgroup/:id",
			// 	name: "Productgroup view",
			// 	component: productgroupView,
			// 	meta: {
			// 		module: "Productgroup",
			// 		action: "view"
			// 	}
			// },
			{
				path: "/404",
				name: "404",
				component: error404,
				meta: {
					module: "Document",
					action: "view"
				}
			},
			{
				path: "/500",
				name: "500",
				component: error500,
				meta: {
					module: "Document",
					action: "view"
				}
			}
		]
	},
	{
		path: "/login",
		name: "Login",
		component: dashboardLogin,
		meta: {
			module: "Dashboard",
			action: "login"
		}
	},
	{
		// the 404 route, when none of the above matches
		path: "/:pathMatch(.*)*",
		redirect: "/404",
	},
];

export const community_access = computed(() => {
	return env("community_access") ?? "portal";
})
export const isCommunity = computed(() => {
	return window.location.pathname.split('/')[1] === community_access.value;
});

const getBaseUrl = () => {
	if (isCommunity.value) {
		return `/${community_access.value}`;
	}
	return "";
};

const router = createRouter({
	saveScrollPosition: false,
	history: createWebHistory(getBaseUrl()),
	base: getBaseUrl(),
	routes
});
function truncate(text, length = 25) {
	if (text.length <= length) {
		return text;
	}
	return text.substr(0, length) + '\u2026';
}

router.beforeEach((to, from, next) => {
	// Check for verification
	console.log(`Route to: "${truncate(to.path)}" from: ${truncate(from.path)}`);
	if (to.path !== "/login" && to.path !== "/forget" && from.path !== '/login' && !checkLogin()) {
		setTimeout(() => {
			if (checkLogin()) {
				return;
			}
			next("/login");
		}, 200);
	}
	if (to.path === "/login") {
		next();
	}

	// Validate visibility
	if (to.path === "/") {
		next();
	} else {
		checkVisibility(to.name).then((visible) => {
			visible ? next() : next("/");
		});
	}

	// Scroll page to top on every route change
	setTimeout(() => {
		window.scrollTo(0, 0);
	}, 100);
});
export default router;