<template>
    <div class="maincontainer">
        <div class="maincolumn">
            <template v-if="viewData.init">
                <!-- TEMPORARY ADDED THIS IF SO SPECIFIC CODE CAN BE ADDED FOR COUPLED PARTS -->
                <template v-for="part in viewData.config.settings?.parts ?? ['default']">
                    <component v-if="part" :is="part" />
                </template>
            </template>
        </div>
    </div>
</template>

<!--            <template v-if="viewData.workflowcard_id">-->
<!--                <button class="backto more-inline" type="button" @click.prevent="goBack()">-->
<!--                    <Icon name="arrow" />-->
<!--                    <span class="title">{{ trans('Terug naar vorige pagina') }}</span>-->
<!--                </button>-->
<!--            </template>-->

<script>
import {defineComponent, provide, watch, computed, reactive, onMounted, onUnmounted, inject, ref} from "vue";

import { useRoute, useRouter } from "vue-router";

import Icon from "components/icon";
import trans from "helpers/_translation";
import {env} from "config";

import { contact, company, item, fetchItem, usergroup_ids, related_value, portalStore } from "store/portal";
import { view_configuration, checkDependecies } from "store/view";
import { resolveRelatedFilters, getFiltersAndStore, generalFilters, resolveFilters, resolveAppFilters } from "store/filters";
import { resetSortingData } from "store/sorting";
import { workflowlayoutdetailtab, workflowlayoutcardoverview } from "store/workflow";

import {getDataWithoutLimit} from "helpers/_api";
import { apiHostname } from "config";

import { workflowproperties } from "store/workflow";

import Parts from "view/dashboard/parts/_index";

export default defineComponent({
    name: "dashboardView",
    components: {
        Icon,
        ...Parts
    },
    setup() {

        const handleConfirm = inject("handleConfirm");

        const route = useRoute();
        const router = useRouter();

        const currentPayload = computed(() => {
            return route.params.payload ? JSON.parse(atob(route.params.payload)) : null;
        });

        const config = computed(() => {
            return view_configuration(route.name);
        });
        const viewData = reactive({
            init: false,
            ticker: 0, // Used for history log for example, updating the feed
            overview_ticker: 0,
            config: config,

            workflowboard_id: computed(() => parseInt(currentPayload.value?.workflowboard_id ?? viewData.config.settings?.target?.workflowboard_id)),
            workflowphase_id: computed(() => parseInt(currentPayload.value?.workflowphase_id ?? viewData.config.settings?.target?.workflowphase_id)),
            workflowcard_id: computed(() => {
                let tmpWorkflowcardId = currentPayload.value?.workflowcard_id ?? viewData.config.settings?.target?.workflowcard_id;
                if (tmpWorkflowcardId && `${tmpWorkflowcardId}`.startsWith("%")) {
                    tmpWorkflowcardId = tmpWorkflowcardId.replace("%", "");
                    tmpWorkflowcardId = related_value(tmpWorkflowcardId);
                }
                return tmpWorkflowcardId;
            }),
            extra_filters: computed(() => viewData.config.settings?.target?.extra_filters),

            // Data specific
            loading: true,
            edited: false,
            item: {
                data: null,
                tabs: null,
                overviews: null,
                active_tab: null
            },
            data: [],

            visible: {
                buttons: computed(() => viewData.config.settings?.disabled?.buttons ? false : true)
            },

            layout: computed(() => {
                if (config.value?.settings?.workflowcard_id) {
                    return "edit";
                }
                return "list";
            }),
            title: computed(() => {
                return config.value.title ?? "";
            }),

            // Cachable data
            workflowboardbuttonbyboardid: {},
        });
        provide("viewData", viewData);

        const extra_tabs = [
            {
                label: "Documenten",
                property: {
                    id: "files",
                    type: "extra"
                }
            },
            {
                label: "Opmerkingen",
                property: {
                    id: "reactions",
                    type: "extra"
                }
            },
            {
                label: "Historie",
                property: {
                    id: "historylog",
                    type: "extra"
                }
            }
        ];

        const overrule = (key, value) => {
            return computed(() => {
                if (viewData.config.settings?.overrule?.[key]?.[value]) {
                    return viewData.config.settings.overrule[key][value];
                }
                return value;
            }).value;
        };
        provide("overrule", overrule);

        const tab_positions = [
            "sidecolumn"
        ];
        const all_tabs = () => {
            return computed(() => {
                if (!viewData.workflowcard_id) return [];
                if (viewData?.item?.tabs === null) return [];
                const details = viewData.item.tabs.filter(a => {
                    return (!a?.disallowed?.read?.some(b => usergroup_ids.value.map(c => `${c}`).includes(`${b}`)))
                        && (!viewData.config?.settings?.disabled?.details || !viewData.config?.settings?.disabled?.details.includes(`${a.property.id}`));
                }).map(a => {
                    a.property.type = "tab";
                    return a;
                });

                const overviews = viewData.item.overviews.map(a => {
                    return {
                        label: a.title,
                        property: {
                            id: a.id,
                            type: "overview"
                        },
                        data: {
                            ...a
                        }
                    };
                }).filter(a => {
                    return !viewData.config?.settings?.disabled?.overviews || !viewData.config?.settings?.disabled?.overviews.includes(`${a.property.id}`);
                });

                const extras = extra_tabs.filter(tab => {
                    return !viewData.config?.settings?.disabled?.tabs || !viewData.config?.settings?.disabled?.tabs.includes(`${tab.property.id}`);
                });
                return [...details, ...overviews, ...extras];
            }).value;
        };
        const tabs = (position = "maincolumn") => {
            return computed(() => {
                let data = [...all_tabs()];

                if (tab_positions.includes(position)) {
                    const tmpSettings = viewData.config.settings?.[`${position}_tabs`] ?? [];
                    data = data.filter(a => tmpSettings.includes(a.property.id));
                } else {
                    tab_positions.forEach(tab_position => {
                        const tmpSettings = viewData.config.settings?.[`${tab_position}_tabs`] ?? [];
                        data = data.filter(a => !tmpSettings.includes(a.property.id));
                    });
                }
                return data;
            }).value;
        };

        const tab_current = () => {
            if (!viewData.item.active_tab) return null;
            // Define our active
            const tab_type = viewData.item.active_tab.split("_")[0];
            const tab_id = viewData.item.active_tab.split("_")[1];
            // Get our tab object
            return tabs().find(a => a.property.id == tab_id && a.property.type == tab_type);
        };
        const handleTab = (tab_type, tab_id) => {
            handleConfirm().then(
                () => {
                    viewData.item.active_tab = `${tab_type}_${tab_id}`;
                },
                () => {
                    // Rejected
                }
            )
        };
        const tab_active = (tab_type, tab_id) => {
            return computed(() => {
                return viewData.item.active_tab == `${tab_type}_${tab_id}`;
            }).value;
        };
        const tab_exists = (tab_type, tab_id) => {
            return computed(() => {
                return tabs().some(a => a.property.id == tab_id && a.property.type == tab_type);
            }).value;
        };
        const tab_editable = (tab_type, tab_id) => {
            const target_tab = tabs().find(a => a.property.id == tab_id && a.property.type == tab_type);
            if (!target_tab) return false;
            return !target_tab?.disallowed?.edit?.some(b => usergroup_ids.value.map(c => `${c}`).includes(`${b}`));
        };

        onMounted(() => {
            checkDependecies(route.name).then(valid => {
                viewData.init = true;
            });

            watch(
                () => [viewData.item.tabs, viewData.item.overviews],
                () => {
                    if (!tabs().length) return;
                    const tmpTab = tabs()[0];
                    handleTab(tmpTab.property.type, tmpTab.property.id);
                },
                {
                    immediate: true
                }
            );

        });

        provide("handleTab", handleTab);
        provide("tabs", tabs);
        provide("tab_active", tab_active);
        provide("tab_exists", tab_exists);
        provide("tab_editable", tab_editable);
        provide("tab_current", tab_current);

        const getData = () => {
            viewData.loading = true;

            const tmpBoardId = `${viewData.workflowboard_id}`;
            const tmpPhaseId = `${viewData.workflowphase_id}`;

            resolveAppFilters(viewData.extra_filters).then(extra_filters => {
                const filters = resolveFilters([
                    [
                        "FieldFilter",
                        {
                            "column": "state",
                            "value": "Online"
                        }
                    ],
                    ...generalFilters.workflowboard[viewData.workflowboard_id],
                    ...generalFilters.workflowusergroup,
                    ...extra_filters
                ]);

                if (viewData.workflowphase_id) {
                    filters.push(...generalFilters.workflowphase[viewData.workflowphase_id]);
                }
                const tmpData = {
                    "data": []
                };
                viewData.data = [];
                viewData.overview_ticker += 1;
                getDataWithoutLimit(`${apiHostname}/api/v1.0/workflowcard`, filters, tmpData, "data").then(
                    () => {
                        // Check if tmp ids still match, otherwise we already switched
                        if (tmpBoardId !== `${viewData.workflowboard_id}` || tmpPhaseId !== `${viewData.workflowphase_id}`) return;
                        // Set data when finished loading instead
                        viewData.data = tmpData.data;
                        viewData.loading = false;
                    }
                );
            });
        };


        let watcher;
        let item_watcher;

        const currentWorkflowcard = computed(() => {
            return portalStore["workflowcard"].find(a => {
                return (`${a.data.id}` === `${viewData.workflowcard_id}`) && a.data.version_date;
            }) ?? null;
        });

        const handleDetailData = () => {
            viewData.item.data = null;
            viewData.item.tabs = null;
            item_watcher = watch(
                () => [
                    contact.value,
                    company.value,
                    currentWorkflowcard.value,
                    item("workflowboard", viewData.workflowboard_id),
                    workflowlayoutcardoverview(viewData.workflowboard_id)
                ],
                (values) => {
                    // Check for version_date since for example workflowcard is filled with .data.id by default
                    if (values.some(a => (a === null) || (!Array.isArray(a) && !a?.data?.version_date))) return;
                    viewData.item.data = item("workflowcard", viewData.workflowcard_id);
                    viewData.loading = false;

                    viewData.item.tabs = workflowlayoutdetailtab(viewData.workflowboard_id);
                    viewData.item.overviews = workflowlayoutcardoverview(viewData.workflowboard_id);

                    item_watcher && item_watcher();
                },
                {
                    immediate: true,
                    deep: true
                }
            );
        };
        watch(
            () => portalStore["workflowcard"],
            () => {
                if (!currentWorkflowcard.value) return;
                handleDetailData();
            },
            {
                immediate: true,
                deep: true
            }
        );
        const handleOverviewData = () => {
            viewData.loading = true;
            viewData.data = [];
            getFiltersAndStore("workflowboard", viewData.workflowboard_id).then(() => {

                // Now check for filters and all dependent data
                item_watcher = watch(
                    () => [
                        item("workflowboard", viewData.workflowboard_id),
                        generalFilters.workflowboard?.[viewData.workflowboard_id],
                        viewData.workflowphase_id ? generalFilters.workflowphase?.[viewData.workflowphase_id] : true
                    ],
                    (values) => {
                        if (values.some(a => (a === null) || (typeof a === "undefined") || (!Array.isArray(a) && typeof a === "Object" && !a?.data?.version_date))) return;
                        getData();
                        item_watcher && item_watcher();
                    },
                    {
                        immediate: true
                    }
                );
            });
        };
        const goBack = () => {
            router.back();
        };

        onMounted(() => {
            viewData.loading = true;
            watcher = watch(
                () => [viewData.workflowcard_id, viewData.workflowphase_id, viewData.workflowboard_id],
                () => {
                    // Fetch all default data
                    viewData.workflowcard_id && fetchItem("workflowcard", viewData.workflowcard_id);
                    // Any other calls can be suspended
                    if (!viewData.workflowboard_id || `${viewData.workflowcard_id}`.startsWith("%")) return;
                    viewData.workflowboard_id && fetchItem("workflowboard", viewData.workflowboard_id);
                    viewData.workflowphase_id && getFiltersAndStore("workflowphase", viewData.workflowphase_id);
                    // Get specific data
                    viewData.workflowcard_id ? handleDetailData() : handleOverviewData();
                },
                {
                    immediate: true
                }
            );

        });
        onUnmounted(() => {
            // Since we leave a view we can reset our data
            resetSortingData();
            watcher && watcher();
            item_watcher && item_watcher();
        });


        const handleItemClick = (e, payload) => {
            const payloadData = btoa(JSON.stringify(payload));
            router.push(`/${route.path.split("/")[1]}/${payloadData}`);
        };
        provide("handleItemClick", handleItemClick);


        const goToOverview = (e) => {
            const payload = {
                workflowboard_id: viewData.workflowboard_id
            };
            const payloadData = btoa(JSON.stringify(payload));
            router.push(`/${route.path.split("/")[1]}/${payloadData}`);
        };
        provide("goToOverview", goToOverview);

        return {
            route,
            trans,
            viewData,

            goBack
        }
    }
});
</script>
